import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchUserContests,
	fetchUserDetail,
	fetchUserProposals,
	fetchUserQuestions,
} from "../../../redux/users.ts";
import { Loader } from "../../../components/loader/index.js";
import { Pagination } from "../../../components/pagination/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Transition,
} from "@headlessui/react";

export function UserDetail() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { userDetail, userQuestions, userProposals, userContests, isLoading } =
		useSelector((store) => store.user);

	useEffect(() => {
		dispatch(fetchUserDetail(id));
	}, []);

	const isActive = userDetail?.AllPosts?.user_data?.is_active;

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{
						name: isActive ? "Active Users" : "Inactive Users",
						link: isActive ? "/users/list/1" : "/users/list/0",
					},
					{ name: "User Details", link: "" },
				]}
			/>
			{isLoading?.userDetail ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader h="20" w="20" color={"orange"} />
				</div>
			) : (
				<div>
					<div className="mx-12 border-2 border-orange-600 pb-10">
						<Disclosure as="div">
							<DisclosureButton className="bg-gray-100 flex justify-start items-start w-full">
								<div className=" text-white ml-8 flex justify-center space-x-2 p-3">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="#F97316"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
										/>
									</svg>
									<div className="text-orange-500">User Details</div>
								</div>
							</DisclosureButton>
							<table className="w-full text-sm text-left rtl:text-right text-gray-500">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50">
									<tr>
										<th scope="col" className="px-6 py-3 text-center">
											Profile Image
										</th>
										<th scope="col" className="px-6 py-3 text-center">
											Name
										</th>
										<th scope="col" className="px-6 py-3 text-center">
											Username
										</th>
										<th scope="col" className="px-6 py-3 text-center">
											Email Address
										</th>
										{/* <th scope="col" className="px-6 py-3 text-center">
											Followers
										</th> */}
										<th scope="col" className="px-6 py-3 text-center">
											Categories
										</th>
										{/* <th scope="col" className="px-6 py-3 text-center">
											User Interest
										</th> */}
									</tr>
								</thead>
								<tbody>
									<tr className="bg-white border-b hover:bg-gray-50">
										<th className="px-6 py-4 flex justify-center">
											<img
												src={userDetail?.AllPosts?.user_data?.profile_photo}
												alt="profile"
												className="rounded-full w-12 h-12"
											/>
										</th>
										<th className="px-6 py-4 text-center">
											<Link
												to={userDetail?.AllPosts?.user_data?.username}
												target="_blank"
												rel="noopener noreferrer"
											>
												{userDetail?.AllPosts?.user_data?.firstname +
													" " +
													userDetail?.AllPosts?.user_data?.lastname}
											</Link>
										</th>
										<td className="px-6 py-4 text-center">
											{userDetail?.AllPosts?.user_data?.username}
										</td>
										<td className="px-6 py-4 text-center">
											{userDetail?.AllPosts?.user_data?.email}
										</td>
										{/* <td className="px-6 py-4 text-center">
											{userDetail?.AllPosts?.count_followers}
										</td> */}
										<td className="px-6 py-4 text-center">
											{userDetail?.UserCategories}
										</td>
										{/* <td className="px-6 py-4 text-center">
											{userDetail?.UserTags}
										</td> */}
									</tr>
								</tbody>
							</table>
						</Disclosure>
						<div className="mx-7 mt-5">
							<Disclosure>
								<DisclosureButton
									className="bg-gray-200 h-10 w-full text-left"
									onClick={() => {
										dispatch(fetchUserQuestions({ id: id, page: 1 }));
									}}
								>
									<div className="ml-8 text-md font-[400] p-2">
										Questions ({userDetail?.AllQuestion})
									</div>
								</DisclosureButton>
								<Transition
									enter="duration-200 ease-out"
									enterFrom="opacity-0 -translate-y-6"
									enterTo="opacity-100 translate-y-0"
									leave="duration-300 ease-out"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 -translate-y-6"
								>
									<DisclosurePanel className="py-3">
										{userDetail?.AllQuestion === 0 ? (
											<div className="mx-9">No item to display</div>
										) : (
											<div className="mx-9">
												{isLoading?.userQuestions ? (
													<div className="flex justify-center">
														<Loader w="20" h="20" color={"orange"} />
													</div>
												) : (
													<div className="flex flex-col space-y-5">
														{userQuestions.data?.map((question) => {
															return (
																<div className="flex flex-col justify-start space-y-3 text-lg border rounded-lg p-3">
																	<Link
																		to={`/users/question-details/${question?.id}`}
																		className="flex justify-start space-x-3"
																	>
																		<div className="font-[700]">
																			(#{question?.id}){" "}
																		</div>
																		<div style={{ wordBreak: "break-word" }}>
																			{question?.description}
																		</div>
																	</Link>
																	<div className="flex flex-wrap justify-start text-blue-600">
																		{question?.categories?.map((cat) => {
																			return (
																				<div className="mr-5 underline text-sm">
																					{cat?.name}
																				</div>
																			);
																		})}
																	</div>
																	<div className="flex justify-start space-x-3">
																		<div className="flex justify-center items-center">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 16 16"
																				fill="currentColor"
																				className="w-4 h-4 ml-1.5"
																			>
																				<path d="M1 8.849c0 1 .738 1.851 1.734 1.947L3 10.82v2.429a.75.75 0 0 0 1.28.53l1.82-1.82A3.484 3.484 0 0 1 5.5 10V9A3.5 3.5 0 0 1 9 5.5h4V4.151c0-1-.739-1.851-1.734-1.947a44.539 44.539 0 0 0-8.532 0C1.738 2.3 1 3.151 1 4.151V8.85Z" />
																				<path d="M7 9a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-.25v1.25a.75.75 0 0 1-1.28.53L9.69 12H9a2 2 0 0 1-2-2V9Z" />
																			</svg>
																			<div>{question?.count_answers}</div>
																		</div>
																		<div className="flex justify-center items-center">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 16 16"
																				fill="currentColor"
																				className="w-4 h-4 ml-1.5"
																			>
																				<path d="M2.09 15a1 1 0 0 0 1-1V8a1 1 0 1 0-2 0v6a1 1 0 0 0 1 1ZM5.765 13H4.09V8c.663 0 1.218-.466 1.556-1.037a4.02 4.02 0 0 1 1.358-1.377c.478-.292.907-.706.989-1.26V4.32a9.03 9.03 0 0 0 0-2.642c-.028-.194.048-.394.224-.479A2 2 0 0 1 11.09 3c0 .812-.08 1.605-.235 2.371a.521.521 0 0 0 .502.629h1.733c1.104 0 2.01.898 1.901 1.997a19.831 19.831 0 0 1-1.081 4.788c-.27.747-.998 1.215-1.793 1.215H9.414c-.215 0-.428-.035-.632-.103l-2.384-.794A2.002 2.002 0 0 0 5.765 13Z" />
																			</svg>
																			<div>{question?.like_vote_question}</div>
																		</div>
																		<div className="flex justify-center items-center">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 16 16"
																				fill="currentColor"
																				className="w-4 h-4"
																			>
																				<path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
																				<path
																					fillRule="evenodd"
																					d="M1.38 8.28a.87.87 0 0 1 0-.566 7.003 7.003 0 0 1 13.238.006.87.87 0 0 1 0 .566A7.003 7.003 0 0 1 1.379 8.28ZM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
																					clip-rule="evenodd"
																				/>
																			</svg>
																			<div>{question?.views}</div>
																		</div>
																		<div className="flex justify-center items-center">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 16 16"
																				fill="currentColor"
																				className="w-4 h-4"
																			>
																				<path d="M12 6a2 2 0 1 0-1.994-1.842L5.323 6.5a2 2 0 1 0 0 3l4.683 2.342a2 2 0 1 0 .67-1.342L5.995 8.158a2.03 2.03 0 0 0 0-.316L10.677 5.5c.353.311.816.5 1.323.5Z" />
																			</svg>
																			<div>{question?.share}</div>
																		</div>
																		<div className="flex justify-center items-center">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 16 16"
																				fill="currentColor"
																				className="w-4 h-4"
																			>
																				<path
																					fillRule="evenodd"
																					d="M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Zm7.75-4.25a.75.75 0 0 0-1.5 0V8c0 .414.336.75.75.75h3.25a.75.75 0 0 0 0-1.5h-2.5v-3.5Z"
																					clipRule="evenodd"
																				/>
																			</svg>
																			<div>{question?.created_at}</div>
																		</div>
																	</div>
																</div>
															);
														})}
													</div>
												)}
												<Pagination
													total={userQuestions?.last_page}
													current={userQuestions?.current_page}
													func={function (page) {
														dispatch(
															fetchUserQuestions({ id: id, page: page })
														);
													}}
												/>
											</div>
										)}
									</DisclosurePanel>
								</Transition>
							</Disclosure>
						</div>
						{/* <div className="mx-7 mt-5">
							<Disclosure>
								<DisclosureButton
									className="bg-gray-200 h-10 w-full"
									onClick={() => {
										dispatch(fetchUserProposals({ id: id, page: 1 }));
									}}
								>
									<div className="ml-8 text-md font-[400] p-2 text-left">
										Proposals ({userDetail?.AllProposals})
									</div>
								</DisclosureButton>
								<Transition
									enter="duration-200 ease-out"
									enterFrom="opacity-0 -translate-y-6"
									enterTo="opacity-100 translate-y-0"
									leave="duration-300 ease-out"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 -translate-y-6"
								>
									<DisclosurePanel className="py-3">
										{userDetail?.AllProposals === 0 ? (
											<div className="mx-9">No item to display</div>
										) : (
											<div className="mx-9">
												{isLoading?.userProposals ? (
													<div className="flex justify-center">
														<Loader w="20" h="20" color={"orange"} />
													</div>
												) : (
													<table className="w-full text-sm text-left rtl:text-right text-gray-500 border">
														<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
															<tr>
																<th
																	scope="col"
																	className="px-6 py-3 text-center"
																>
																	Primary Id
																</th>
																<th
																	scope="col"
																	className="px-6 py-3 text-center"
																>
																	Proposal Description
																</th>
															</tr>
														</thead>
														<tbody>
															{userProposals?.data?.map((proposal) => {
																return (
																	<tr className="bg-white border-b hover:bg-gray-50">
																		<th
																			scope="row"
																			className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center"
																		>
																			{proposal?.id}
																		</th>
																		<th className="px-6 py-4 text-center">
																			{proposal?.description}
																		</th>
																	</tr>
																);
															})}
														</tbody>
													</table>
												)}
												<Pagination
													total={userProposals?.last_page}
													current={userProposals?.current_page}
													func={function (page) {
														dispatch(
															fetchUserProposals({ id: id, page: page })
														);
													}}
												/>
											</div>
										)}
									</DisclosurePanel>
								</Transition>
							</Disclosure>
						</div>
						<div className="mx-7 mt-5">
							<Disclosure>
								<DisclosureButton
									className="bg-gray-200 h-10 w-full"
									onClick={() => {
										dispatch(fetchUserContests({ id: id, page: 1 }));
									}}
								>
									<div className="ml-8 text-md font-[400] text-left p-2">
										Contests ({userDetail?.AllContest})
									</div>
								</DisclosureButton>
								<Transition
									enter="duration-200 ease-out"
									enterFrom="opacity-0 -translate-y-6"
									enterTo="opacity-100 translate-y-0"
									leave="duration-300 ease-out"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 -translate-y-6"
								>
									<DisclosurePanel className="py-3">
										{userDetail?.AllContest === 0 ? (
											<div className="mx-9">No item to display</div>
										) : (
											<div className="mx-9">
												{isLoading?.userContests ? (
													<div className="flex justify-center">
														<Loader w="20" h="20" color={"orange"} />
													</div>
												) : (
													<table className="w-full text-sm text-left rtl:text-right text-gray-500 border">
														<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
															<tr>
																<th
																	scope="col"
																	className="px-6 py-3 text-center"
																>
																	Primary Id
																</th>
																<th
																	scope="col"
																	className="px-6 py-3 text-center"
																>
																	Contest Title
																</th>
																<th
																	scope="col"
																	className="px-6 py-3 text-center"
																>
																	Price / Commission
																</th>
																<th
																	scope="col"
																	className="px-6 py-3 text-center"
																>
																	Status / Type
																</th>
																<th
																	scope="col"
																	className="px-6 py-3 text-center"
																>
																	Created Date
																</th>
															</tr>
														</thead>
														<tbody>
															{userContests?.data?.map((contest) => {
																return (
																	<tr className="bg-white border-b hover:bg-gray-50">
																		<th
																			scope="row"
																			className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center"
																		>
																			{contest?.id}
																		</th>
																		<th className="px-6 py-4 text-center">
																			{contest?.title}
																		</th>
																		<th className="px-6 py-4 text-center">
																			${contest?.prize_amount} / $
																			{contest?.prize_amount / 10}
																		</th>
																		<th className="px-6 py-4 text-center">
																			{contest?.is_active === 0
																				? "Draft"
																				: "Active"}
																		</th>
																		<th className="px-6 py-4 text-center">
																			{contest?.created_at}
																		</th>
																	</tr>
																);
															})}
														</tbody>
													</table>
												)}
												<Pagination
													total={userContests?.last_page}
													current={userContests?.current_page}
													func={function (page) {
														dispatch(fetchUserContests({ id: id, page: page }));
													}}
												/>
											</div>
										)}
									</DisclosurePanel>
								</Transition>
							</Disclosure>
						</div> */}
					</div>
				</div>
			)}
		</>
	);
}
