import { useEffect, useRef, useState } from "react";
import DeleteModal from "./components/termModal";
import emitter from "./services/emitter-service";
import { SignInPage } from "./pages/signIn";
import { Routes, Route } from "react-router-dom";
import { Navbar } from "./components/navbar";
import { HomePage } from "./pages/homepage";
import Api from "./services/api";
import { ProtectedRoutes } from "./util/protectedRoutes";
import SignOut from "./components/signoutModal";
import { ViewCourses } from "./pages/viewCourses";
import { useSelector } from "react-redux";
import { Course } from "./containers/courses/course";
import { Term } from "./pages/terms";
import { AddCourse } from "./containers/courses/addcourse";
import { Vouchers } from "./containers/vouchers/viewVouchers";
import { AddVoucher } from "./containers/vouchers/addVoucher/index.js";
import { Pending } from "./containers/vouchers/pending/index.js";
import { Processing } from "./containers/vouchers/processing/index.js";
import { Paid } from "./containers/vouchers/paid/index.js";
import { AllTickets } from "./pages/tickets/index.js";
import { ContactUs } from "./pages/actionList/index.js";
import { Newsletters } from "./pages/newsletters/index.js";
import { GenerateEmail } from "./containers/vouchers/generateEmail/index.js";
import { CreateEmail } from "./containers/vouchers/generateEmail/createEmail.js";
import { ReportAbuse } from "./pages/reportAbuse/index.js";
import { Reports } from "./pages/reports/index.js";
import { ServerError } from "./pages/serverError/index.js";
import { ActiveUsers } from "./containers/users/activeUsers/index.js";
import { UserDetail } from "./containers/users/userDetail/index.js";
import { ErrorLogs } from "./pages/errorLogs/index.js";
import { CandidateRequests } from "./containers/requests/candidate/index.js";
import { EmployeeRequests } from "./containers/requests/employee/index.js";
import { LoginActivity } from "./containers/users/activeUsers/loginActivity/index.js";
import { OverallUsers } from "./containers/users/overall/index.js";
import { QuestionDetails } from "./containers/users/overall/questionDetails/index.js";
import { AdminUserRoles } from "./containers/users/adminRoles/index.js";
import { OurTeam } from "./pages/team/index.js";
import { AddTeam } from "./containers/team/teamAdd/index.js";
import { TeamInfo } from "./containers/team/teamInfo/index.js";
import { EditTeam } from "./containers/team/teamEdit/index.js";
import { CaseStudies } from "./pages/caseStudies/index.js";
import { AddCaseStudy } from "./containers/caseStudies/addCaseStudy.js";
import { InfoHome } from "./containers/caseStudies/caseStudyInfo/index.js";
import { EditCaseStudy } from "./containers/caseStudies/editCaseStudy.js";
import { AddSection } from "./containers/caseStudies/sections/addSection.js";
import { SectionInfo } from "./containers/caseStudies/sections/sectionInfo.js";
import { EditSection } from "./containers/caseStudies/sections/editSection.js";
import { BlogsHome } from "./pages/blogs/index.js";
import { BlogDetails } from "./containers/blogs/blogInfo/index.js";
import { AddBlog } from "./containers/blogs/addBlog.js";
import { EditBlog } from "./containers/blogs/editBlog.js";
import { CategoryInfo } from "./containers/blogs/categories/categoryInfo.js";
import { FaqsHome } from "./pages/faqs/index.js";
import { FaqCategoryInfo } from "./containers/faqs/categories/categoryInfo.js";
import { FaqPageInfo } from "./containers/faqs/pages/pageInfo.js";
import { FaqDetails } from "./containers/faqs/faqInfo/index.js";
import { AddFaq } from "./containers/faqs/addFaq.js";
import { EditFaq } from "./containers/faqs/editFaq.js";
import { EventResponse } from "./pages/eventResponse/index.js";
import { EmailDetails } from "./containers/vouchers/generateEmail/emailDetails.js";
import { JobDetails } from "./containers/jobs/jobInfo/index.js";
import { Jobs } from "./containers/jobs/index.js";
import { JobsFilterUpdate } from "./containers/jobs/jobFilterUpdate/index.jsx";
import { AddUpdateJobs } from "./containers/jobs/addUpdate/index.js";
import { PackageBased } from "./containers/courses/orders/packageBased/index.js";
import { ViewUsers } from "./containers/jobs/ViewUsers/index.jsx";
import { KnowledgeBank } from "./containers/buyer/knowledgeBank/index.jsx";
import { ViewApplicants } from "./containers/jobs/viewApplicants/index.jsx";
import { JobApplication } from "./containers/jobs/jobApplication/index.jsx";
import { PriceTagSetting } from "./containers/influncers/priceSetting/index.jsx";
import { Dashboard } from "./pages/dashboard/index.jsx";
import { Toaster } from "react-hot-toast";
import { Companies } from "./containers/jobs/companies/index.jsx";
import { HierarchyChart } from "./pages/hierarchyChart/index.jsx";
import { BSPolicies } from "./pages/bsPolicies/index.jsx";
import { AddUpdatePolicy } from "./containers/bsPolicies/addUpdate/index.jsx";
import { Trainers } from "./pages/trainners/index.jsx";
import { AddUpdateTrainers } from "./containers/trainers/addUpdate/index.jsx";
import { CompanyQueries } from "./pages/companyQueries/index.jsx";
import { CompanyFilters } from "./pages/companyFilters/index.jsx";
import { InfluencersProfile } from "./containers/influncers/profiles/index.jsx";
import { InfluencerFilters } from "./containers/influncers/filters/index.jsx";
import { PostedContent } from "./containers/influncers/PostedContent/index.jsx";
import { SingleContent } from "./containers/influncers/SingleContent/index.jsx";
import { SystemSettings } from "./containers/influncers/SystemSettings/index.jsx";
import { BulkUpdate } from "./containers/jobs/BulkUpdate/index.jsx";

Api.init({
  url: `${process.env.REACT_APP_SERVER_URL}/api/admin`,
});

export const Router = () => {
  const { token, userInfo } = useSelector((store) => store.login);
  const [open, setOpen] = useState(false);

  if (token) {
    Api.setClientToken(token);
    Api.verifyResponse(token);
  }

  useEffect(() => {
    if (token) {
      Api.setClientToken(token);
      Api.verifyResponse(token);
    }
  }, [token]);

  const popupRef = useRef(null);
  const signOutRef = useRef(null);

  useEffect(() => {
    emitter.on("FinishPopUp", onDeletePopUp);
    emitter.on("SignOutPopUp", onSignOutPopUp);
  });

  const onDeletePopUp = (data) => {
    popupRef?.current?.open(data);
  };

  const onSignOutPopUp = () => {
    signOutRef?.current?.open();
    setOpen(false);
  };

  const protectedRoutesSuperAdmin = [
    { path: "*", element: <HomePage /> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/offerings/:type", element: <ViewCourses /> },
    { path: "/payment-request/orders", element: <PackageBased /> },
    { path: "/offerings/:type/:title/*", element: <Course /> },
    { path: "/offerings/:offtype/action/:type/:id?", element: <AddCourse /> },
    { path: "/payment-request/view-voucher", element: <Vouchers /> },
    { path: "/payment-request/add-voucher", element: <AddVoucher /> },
    { path: "/payment-request/update-voucher/:id", element: <AddVoucher /> },
    { path: "/payment-request/all-emails", element: <GenerateEmail /> },
    { path: "/payment-request/all-emails/generate", element: <CreateEmail /> },
    { path: "/payment-request/email/:id", element: <EmailDetails /> },
    { path: "/payment-request/pending", element: <Pending /> },
    { path: "/payment-request/processing", element: <Processing /> },
    { path: "/payment-request/paid", element: <Paid /> },
    { path: "/tickets/view-all", element: <AllTickets /> },
    { path: "/others/action-list", element: <ContactUs /> },
    { path: "/others/quote-requests", element: <ContactUs type={'rfq'} /> },
    { path: "/others/request-join-research", element: <ContactUs type={'request-join-research'} /> },
    { path: "/others/expert-hiring-form", element: <ContactUs type={'expert-hiring-form'} /> },
    { path: "/others/newsletters", element: <Newsletters /> },
    { path: "/others/report-abuse", element: <ReportAbuse /> },
    { path: "/others/reports", element: <Reports /> },
    { path: "/others/error-logs", element: <ErrorLogs /> },
    { path: "/policy/:policyname?", element: <Term /> },
    { path: "/users/list/:status", element: <ActiveUsers /> },
    { path: "/users/overall", element: <OverallUsers /> },
    { path: "/users/admin-user-roles", element: <AdminUserRoles /> },
    { path: "/users/question-details/:id", element: <QuestionDetails /> },
    { path: "/users/login-activity/:id", element: <LoginActivity /> },
    { path: "/users/user-detail/:id", element: <UserDetail /> },
    { path: "/jobs/candidate-request", element: <CandidateRequests /> },
    { path: "/jobs/employee-request", element: <EmployeeRequests /> },
    { path: "/contents/team", element: <OurTeam /> },
    { path: "/contents/trainers", element: <Trainers /> },
    { path: "/contents/trainers/:type/:id?", element: <AddUpdateTrainers /> },
    { path: "/contents/team/:id", element: <TeamInfo /> },
    { path: "/contents/team/add", element: <AddTeam /> },
    { path: "/contents/team/edit/:id", element: <EditTeam /> },
    { path: "/contents/case-studies", element: <CaseStudies /> },
    { path: "/contents/case-studies/:id/:type", element: <InfoHome /> },
    { path: "/contents/case-studies/add", element: <AddCaseStudy /> },
    { path: "/contents/case-studies/edit/:id", element: <EditCaseStudy /> },
    { path: "/contents/case-studies/:id/sections/add", element: <AddSection /> },
    { path: "/contents/case-studies/:faqId/sections/:id", element: <SectionInfo /> },
    { path: "/contents/case-studies/:faqId/sections/edit/:id", element: <EditSection /> },
    { path: "/contents/blogs/:type?", element: <BlogsHome /> },
    { path: "/contents/blog/:id", element: <BlogDetails /> },
    { path: "/contents/blog/add", element: <AddBlog /> },
    { path: "/contents/blog/edit/:id", element: <EditBlog /> },
    { path: "/contents/blogs/categories/:id", element: <CategoryInfo /> },
    { path: "/contents/faqs/:type?", element: <FaqsHome /> },
    { path: "/contents/faq/add", element: <AddFaq /> },
    { path: "/contents/faq/edit/:id", element: <EditFaq /> },
    { path: "/contents/faq/:id", element: <FaqDetails /> },
    { path: "/contents/faqs/categories/:id", element: <FaqCategoryInfo /> },
    { path: "/contents/faqs/pages/:id", element: <FaqPageInfo /> },
    { path: "/jobs/view-all", element: <Jobs /> },
    { path: "/jobs/bulk-update", element: <BulkUpdate /> },
    { path: "/jobs/all-applications", element: <ViewApplicants /> },
    { path: "/jobs/filter", element: <JobsFilterUpdate /> },
    { path: "/jobs/companies", element: <Companies /> },
    { path: "/jobs/detail/:id", element: <JobDetails /> },
    { path: "/jobs/:type/:id?", element: <AddUpdateJobs /> },
    { path: "/jobs/view-users/:id", element: <ViewUsers /> },
    { path: "/jobs/view-applicants/:id", element: <ViewApplicants /> },
    { path: "/jobs/job-application/:id", element: <JobApplication /> },
    { path: "/buyer/knowledge-bank", element: <KnowledgeBank /> },
    { path: "/influencers/price-setting", element: <PriceTagSetting /> },
    { path: "/influencers/profiles", element: <InfluencersProfile /> },
    { path: "/influencers/posted-content", element: <PostedContent /> },
    { path: "/influencers/posted-content/:id", element: <SingleContent /> },
    { path: "/influencers/filters", element: <InfluencerFilters /> },
    { path: "/influencers/system-settings", element: <SystemSettings /> },
    { path: "/companies/all", element: <Companies /> },
    { path: "/companies/queries", element: <CompanyQueries /> },
    { path: "/companies/filters", element: <CompanyFilters /> },
    { path: "/bs/hierarchy-chart", element: <HierarchyChart /> },
    { path: "/bs/policies", element: <BSPolicies /> },
    { path: "/bs/policies/:type/:id?", element: <AddUpdatePolicy /> },
  ];

  const protectedRoutesAdmin = [
    { path: "*", element: <HomePage /> },
    { path: "/dashboard", element: <Dashboard /> },
    // { path: "/offerings/:type", element: <ViewCourses /> },
    // { path: "/offerings/:type/:title/*", element: <Course /> },
    // { path: "/offerings/:offtype/action/:type/:id?", element: <AddCourse /> },
    { path: "/policy/:policyname?", element: <Term /> },
    { path: "/jobs/view-all", element: <Jobs /> },
    { path: "/jobs/bulk-update", element: <BulkUpdate /> },
    { path: "/jobs/all-applications", element: <ViewApplicants /> },
    { path: "/jobs/filter", element: <JobsFilterUpdate /> },
    { path: "/jobs/companies", element: <Companies /> },
    { path: "/jobs/detail/:id", element: <JobDetails /> },
    { path: "/jobs/:type/:id?", element: <AddUpdateJobs /> },
    { path: "/jobs/view-users/:id", element: <ViewUsers /> },
    { path: "/jobs/view-applicants/:id", element: <ViewApplicants /> },
    { path: "/jobs/job-application/:id", element: <JobApplication /> },
    { path: "/jobs/candidate-request", element: <CandidateRequests /> },
    { path: "/jobs/employee-request", element: <EmployeeRequests /> },
  ];

  return (
    <>
      <Navbar open={open} setOpen={setOpen} token={token} />
      <DeleteModal ref={popupRef} />
      <SignOut ref={signOutRef} />
      <Toaster />
      <div
        className={`mt-16 ${
          (open && token) ? "ml-56" : null
        } transition-all duration-200 overflow-hidden ease-in-out`}
      >
        <ServerError>
          <Routes>
            <Route
              path="/signin"
              element={<SignInPage />}
            />

            {userInfo?.ability === "Super-Admin"
              ? protectedRoutesSuperAdmin?.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={route?.path}
                      element={
                        <ProtectedRoutes>{route?.element}</ProtectedRoutes>
                      }
                    />
                  );
                })
              : protectedRoutesAdmin?.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={route?.path}
                      element={
                        <ProtectedRoutes>{route?.element}</ProtectedRoutes>
                      }
                    />
                  );
                })}
          </Routes>
        </ServerError>
      </div>
    </>
  );
};
