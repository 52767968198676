import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTeamMember } from "../../../redux/teamSlice.ts";
import { Loader } from "../../../components/loader/index.js";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { Upload } from "../../../components/upload/index.jsx";

export const AddTeam = () => {
	const [data, setData] = useState();
	const [error, setError] = useState("");
	const [imagePreview, setImagePreview] = useState(null);
	const { isLoading } = useSelector((store) => store.team);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleFileChange = async (e) => {
		const files = e.target.files;

		const isImage = files[0]?.type.startsWith("image/");
		const isSizeValid = files[0]?.size / 1024 <= 1024;

		if (!isImage) {
			setError("Only image files are allowed!");
		} else if (!isSizeValid) {
			setError("Size exceeded limit of 1024 KB!");
		} else {
			setError("");
			const file = files[0];

			setData({ ...data, image_path: file });

			const reader = new FileReader();
			reader.onloadend = () => {
				// Set the image preview using the data URL
				setImagePreview(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleAddTeam = async (e) => {
		e.preventDefault();
		const combinedData = { ...data };

		if (
			!combinedData.name ||
			!combinedData.title ||
			!combinedData.description ||
			combinedData.description === "<p></p>\n"
		) {
			const errorMessage = !combinedData.name
				? "Name is required field."
				: !combinedData.title
				? "Title is required field."
				: !combinedData.description
				? "Description is required field."
				: "";
			setError(errorMessage);
			return;
		}
		if (!combinedData?.image_path) {
			setError("Image field is required!");
			return;
		}
		const formData = new FormData();

		if (typeof combinedData?.image_path === "string") {
			delete combinedData?.image_path;
		}

		Object.keys(combinedData).forEach((key) => {
			formData.append(key, combinedData[key]);
		});
		setError("");
		const json = await dispatch(
			addTeamMember({ formData: { formData } }, navigate)
		);
		if (json?.response?.status === 422) {
			console.log(json?.response?.data?.error);
			setError(
				json?.response?.data?.error?.[
					Object.keys(json?.response?.data?.error || {})?.[0]
				]
			);
		}
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Our Team", link: "/contents/team" },
					{ name: "Add Team Member", link: "" },
				]}
			/>
			<div className="bg-white mx-[55px] shadow-lg rounded-lg pb-4 sm:p-6 sm:pb-4">
				<div className="flex flex-col justify-center items-center">
					<form onSubmit={handleAddTeam} className="w-full mx-auto">
						<div className="grid md:grid-cols-4 md:gap-20">
							<div className="relative md:col-start-1 md:col-span-2 w-full mb-5 group">
								<input
									type="text"
									name="name"
									id="name"
									className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
									placeholder=" "
									value={data?.name || ""}
									onChange={(e) => setData({ ...data, name: e.target.value })}
									required
								/>
								<label
									htmlFor="name"
									className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									Name *
								</label>
							</div>
							<div className="md:col-start-3 md:col-span-2 relative  w-full mb-5 group">
								<input
									type="text"
									name="title"
									id="title"
									className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
									placeholder=" "
									value={data?.title || ""}
									onChange={(e) => setData({ ...data, title: e.target.value })}
									required
								/>
								<label
									htmlFor="title"
									className="peer-focus:font-medium absolute text-sm text-orange-500   duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									Title *
								</label>
							</div>
						</div>
						<div className="grid md:grid-cols-4 md:gap-20">
							<div className="relative md:col-start-1 md:col-span-2  w-full mb-5 group">
								<input
									type="text"
									name="linkedin"
									id="linkedin"
									className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
									placeholder=" "
									value={data?.linkedin || ""}
									onChange={(e) =>
										setData({ ...data, linkedin: e.target.value })
									}
								/>
								<label
									htmlFor="linkedin"
									className="peer-focus:font-medium absolute text-sm text-orange-500   duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									LinkedIn
								</label>
							</div>
							<div className="md:col-start-3 md:col-span-2 relative  w-full mb-5 group">
								<input
									type="text"
									name="quote"
									id="quote"
									className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
									placeholder=" "
									value={data?.quote || ""}
									onChange={(e) => setData({ ...data, quote: e.target.value })}
								/>
								<label
									htmlFor="quote"
									className="peer-focus:font-medium absolute text-sm text-orange-500   duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									Quote
								</label>
							</div>
						</div>
						<div className="grid md:grid-cols-4 md:gap-20">
							<div className="relative md:col-start-1 md:col-span-2  w-full mb-5 group">
								<input
									type="number"
									name="order_by"
									id="order_by"
									className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
									placeholder=" "
									value={data?.order_by || ""}
									onChange={(e) => {
										const inputValue = e.target.value;
										if (/^\d*$/.test(inputValue) || inputValue === "") {
											setData({ ...data, order_by: inputValue });
										}
									}}
								/>
								<label
									htmlFor="order_by"
									className="peer-focus:font-medium absolute text-sm text-orange-500   duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									Order By
								</label>
							</div>
						</div>
						<div className="grid md:grid-cols-4 md:gap-20 mt-4">
							<div className="relative md:col-start-1 md:col-span-4 w-full mb-5">
								<textarea
									//type="text"
									name="description"
									id="description"
									className="block p-2 w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600"
									placeholder=" "
									maxLength={600}
									value={data?.description || ""}
									onChange={(e) =>
										setData({ ...data, description: e.target.value })
									}
								/>
								<label className="peer-focus:font-medium absolute text-orange-500 text-lg  duration-300 transform -translate-y-6 scale-75 top-0 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
									Description *
								</label>
							</div>
						</div>
						<div className="md:grid md:grid-cols-3 mt-5">
							<div className="relative  w-full mb-5 group md:col-start-1 md:col-span-1">
								<label
									htmlFor="thumbnail"
									className="peer-focus:font-medium absolute text-sm text-orange-500   duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
								>
									Team Image *
								</label>
								<Upload id={"thumbnail"}>
									<input
										id="thumbnail"
										type="file"
										accept="image/*"
										hidden="true"
										name="thumbnail"
										value={data?.thumbnail || ""}
										onChange={handleFileChange}
										max={1}
									/>
								</Upload>
							</div>
						</div>
						{imagePreview && (
							<img
								src={imagePreview}
								alt="Uploaded Preview"
								className="mb-4 w-24 h-24 rounded"
							/>
						)}
						{error && <p className="my-2 text-red-500">{error}</p>}
						<button className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out">
							{isLoading?.addTeam ? (
								<div>
									<Loader h="6" w="6" color={"blue"} />
								</div>
							) : (
								"Add"
							)}
						</button>
					</form>
				</div>
			</div>
		</>
	);
};
